const depositosService = http => {
  const endpoint = `${process.env.VUE_APP_API_BASE_URL}finance/deposit`
  const endpointAdmin = `${process.env.VUE_APP_API_BASE_URL}finance/admin/deposit`

  const get = () => {
    const url = `${endpoint}/my-deposit`
    return http.get(url).then(data => data.data)
  }

  const getAdmin = idStatus => {
    // eslint-disable-next-line no-param-reassign
    idStatus = idStatus === undefined ? '' : idStatus
    const url = `${endpointAdmin}/list/${idStatus}`
    return http.get(url).then(data => data.data)
  }

  const post = data => http.post(`${endpoint}/store`, data)
  const alterar = data => http.post(`${endpointAdmin}/alter-status-deposit`, data)

  return {
    get,
    getAdmin,
    alterar,
    post,
  }
}

export {
  // eslint-disable-next-line import/prefer-default-export
  depositosService,
}
