<template>
  <b-card :title="`${$t('approve_deposits.list_pending')}`">
    <b-card-text>
      <!-- search input -->
      <div class="custom-search d-flex justify-content-between mb-1">
        <b-form-group>
          <label for="status">Status:</label>
          <b-form-select
            id="status"
            v-model="status"
            name="status"
            :options="status_options"
            @change="selectStatus()"
          />
        </b-form-group>
        <b-form-group>
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="searchTerm"
              :placeholder="`${$t('approve_deposits.field_search')}`"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Status -->
          <span v-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ statusVariantLabel(props.row.status) }}
            </b-badge>
          </span>

          <span v-else-if="props.column.field === 'coin_fiat_deposit'">
            {{ props.row.coin_fiat_deposit.toUpperCase() }}
          </span>

          <a
            v-else-if="props.column.field === 'uuid'"
            href="javascript:;"
            @click="copyuuid(props.row.uuid)"
          >
            <feather-icon icon="CopyIcon" />
            {{ props.row.uuid.substr(0, 4) }}...
          </a>

          <span v-else-if="props.column.field === 'created_at'">
            {{ props.row.created_at | moment('DD/MM/YYYY - H:mm:ss') }}
          </span>

          <span v-else-if="props.column.field === 'action'">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="info"
              class="mr-1"
              size="sm"
              @click="visualizarItem(props.row.originalIndex)"
            >
              <feather-icon
                icon="Edit2Icon"
                class="mr-50"
              />
              <span>{{ $t('approve_deposits.actions.view') }}</span>
            </b-button>
          </span>

          <span v-else-if="props.column.field === 'declared_value_deposit'">
            {{ formatPrice(props.row.declared_value_deposit) }}
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </b-card-text>

    <b-modal
      id="modalView"
      centered
      :title="`${$t('approve_deposits.modal_info.title')}`"
      hide-footer
    >
      <b-card-text v-if="renderView">
        <b-badge variant="dark">
          {{ viewItem.created_at | moment('DD/MM/YYYY - H:mm:ss') }}
        </b-badge>
        <b-badge
          variant="warning"
          class="mx-1"
        >
          {{ viewItem.ip }}
        </b-badge>
        <b-badge :variant="statusVariant(viewItem.status)">
          {{ statusVariantLabel(viewItem.status) }}
        </b-badge><br><br>
        <p><strong>{{ $t('approve_deposits.modal_info.depositary') }}:</strong> {{ viewItem.user.name }}</p>
        <ul>
          <li>
            <strong>{{ $t('approve_deposits.modal_info.coin') }}:</strong> {{ viewItem.coin_fiat_deposit.toUpperCase() }}
          </li>
          <li>
            <strong>{{ $t('approve_deposits.modal_info.declared_value') }}:</strong> {{ viewItem.declared_value_deposit }}
          </li>
          <li>
            <strong>{{ viewItem.status === 2 ? $t('deposits.modal_info.denied_value') : $t('deposits.modal_info.final_value') }}:</strong> {{ viewItem.value_deposit }}
          </li>
          <li>
            <strong>{{ $t('approve_deposits.modal_info.user_notes') }}:</strong> {{ viewItem.note }}
          </li>
        </ul>
        <p>
          <a
            :href="viewItem.payment_voucher !== null ? viewItem.payment_voucher : ''"
            target="_blank"
            class="font-weight-bold"
          >{{ $t('approve_deposits.modal_info.view_receipt') }}</a>
        </p>
        <hr>
        <validation-observer ref="depositoForm">
          <b-form @submit.prevent>
            <b-form-group>
              <label for="status">{{ $t('approve_deposits.modal_info.form.alter_status') }}</label>
              <validation-provider
                #default="{ errors }"
                name="status"
                rules="required"
              >
                <b-form-select
                  id="status"
                  v-model="status"
                  name="status"
                  :options="options"
                  :state="errors.length > 0 ? false : null"
                  class="mb-1"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              :label="`${$t('approve_deposits.modal_info.form.confirmed_value')}`"
              label-for="valor"
            >
              <validation-provider
                #default="{ errors }"
                name="valor"
                rules="required"
              >
                <b-form-input
                  id="valor"
                  v-model.lazy="value_deposit"
                  v-money="money"
                  name="valor"
                  :state="errors.length > 0 ? false : null"
                  placeholder="0,00"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              :label="`${$t('approve_deposits.modal_info.form.notes')}`"
              label-for="note_admin"
            >
              <validation-provider
                #default="{ errors }"
                name="note_admin"
                rules="required"
              >
                <b-form-textarea
                  id="note_admin"
                  v-model="note_admin"
                  name="note_admin"
                  :placeholder="`${$t('approve_deposits.modal_info.form.notes')}...`"
                  rows="3"
                  max-rows="6"
                  maxlength="255"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              block
              class="mt-1"
              @click="validationForm"
            >
              {{ $t('approve_deposits.modal_info.form.button_submit') }}
            </b-button>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BFormGroup,
  BFormInput,
  BButton,
  BBadge,
  BForm,
  BFormSelect,
  BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import { required } from '@validations'
import { depositosService } from '@/services/depositos'
import { VMoney } from 'v-money'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
    BCard,
    BCardText,
    BFormInput,
    BForm,
    BFormGroup,
    BButton,
    BFormSelect,
    BFormTextarea,
    BBadge,
  },
  directives: {
    Ripple,
    money: VMoney,
  },
  data() {
    return {
      depositosService: depositosService(this.$http),
      required,
      dir: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false, /* doesn't work with directive */
      },
      rows: [],
      searchTerm: '',
      default: {
        0: 'Aguardando Aprovação',
        1: 'Aprovado',
      },
      options: [
        { value: '', text: 'Selecione', disabled: true },
        { value: 1, text: 'Aprovar' },
        { value: 2, text: 'Reprovar' },
      ],
      renderView: false,
      viewItem: {},
      urlApi: process.env.VUE_APP_IMG_BASE_URL,
      status: 0,
      value_deposit: '',
      note_admin: '',
    }
  },
  computed: {
    columns() {
      return [
        {
          label: this.$t('approve_deposits.table_header.code'),
          field: 'uuid',
        },
        {
          label: this.$t('approve_deposits.table_header.depositary'),
          field: 'user.name',
        },
        {
          label: this.$t('approve_deposits.table_header.time'),
          field: 'created_at',
        },
        {
          label: this.$t('approve_deposits.table_header.deposit_value'),
          field: 'declared_value_deposit',
          sortable: false,
        },
        {
          label: this.$t('approve_deposits.table_header.coin'),
          field: 'coin_fiat_deposit',
        },
        {
          label: this.$t('approve_deposits.table_header.status'),
          field: 'status',
        },
        {
          label: this.$t('approve_deposits.table_header.action'),
          field: 'action',
          sortable: false,
        },
      ]
    },
    status_options() {
      return [
        { value: null, text: this.$t('deposits.deposit_status.select'), disabled: true },
        { value: 0, text: this.$t('deposits.deposit_status.pending') },
        { value: 1, text: this.$t('deposits.deposit_status.approved') },
        { value: 2, text: this.$t('deposits.deposit_status.denied') },
      ]
    },
    statusVariantLabel() {
      const statusLabel = {
        /* eslint-disable key-spacing */
        0: this.$t('deposits.deposit_status.pending'),
        1: this.$t('deposits.deposit_status.approved'),
        2: this.$t('deposits.deposit_status.denied'),
        /* eslint-enable key-spacing */
      }

      return status => statusLabel[status]
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        2: 'light-danger',
        1: 'light-success',
        0: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.listItens()
  },
  methods: {
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    selectStatus() {
      this.listItens(this.status)
    },
    async listItens(id) {
      const resultado = await this.depositosService.getAdmin(id)
      this.rows = resultado.data.deposits
    },
    async visualizarItem(id) {
      this.viewItem = await this.rows[id]
      this.renderView = await true
      this.$bvModal.show('modalView')
    },
    validationForm() {
      // eslint-disable-next-line consistent-return
      this.$refs.depositoForm.validate().then(async success => {
        if (success) {
          try {
            const depositFinalValue = this.value_deposit
              .replace(/\.*/g, '')
              .replace(',', '.')

            const data = await {
              deposit_uuid: this.viewItem.uuid,
              status: this.status,
              value_deposit: depositFinalValue,
              note_admin: this.note_admin,
            }

            await this.depositosService.alterar(data)

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Depósito atualizado com sucesso',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })

            this.$bvModal.hide('modalView')
            this.listItens()
            this.newDeposito = {}
            this.status = ''
            this.value_deposit = 0
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Erro ao enviar atualizar.',
                text: 'Algo deu errado!',
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      })
    },
    copyuuid(uuid) {
      /* Copy the text inside the text field */
      navigator.clipboard.writeText(uuid)
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Código Copiado!',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
    },
  },
}
</script>
